import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonList, IonModal, IonRow, IonText, IonToolbar } from "@ionic/react";
import { close } from 'ionicons/icons';
import { useEffect, useRef, useState } from "react";
import { BankAccount } from "../../../lib";
import { ListBankAccountsFromRequestIdAsync } from "../../../lib/data/Ais";
import PageSpinner from "./PageSpinner";

export interface BankAccountSelectorProps {
	requestId: string,
	onSelected: (bankAccount: BankAccount) => void,
	onCancel: Function,
}

export default function BankAccountSelector(props: BankAccountSelectorProps) {
	const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
	const isLoadingBankAccounts = useRef(false);

	useEffect(() => {
		if (isLoadingBankAccounts.current === true) return;
		isLoadingBankAccounts.current = true;

		ListBankAccountsFromRequestIdAsync(props.requestId).then((response) => {
			if (response.length === 1) {
				props.onSelected(response[0]);
				return;
			}

			setBankAccounts(response);
		});
	}, [props]);

	function clearBankAccounts() {
		setBankAccounts([]);
		isLoadingBankAccounts.current = false;
	}

	function closeControl() {
		clearBankAccounts();
		props.onCancel();
	};

	async function chooseBankAccount(bankAccount: BankAccount) {
		props.onSelected(bankAccount);
		clearBankAccounts();
	};

	return <>
		<IonModal isOpen={true} backdropDismiss={false}>
			{bankAccounts.length > 0
				? <IonContent>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={closeControl}>
								<IonIcon slot="icon-only" icon={close} />
							</IonButton>
						</IonButtons>
					</IonToolbar>
					<IonGrid>
						<IonRow style={{marginBottom: '-15px'}}>
							<IonCol>
								<IonText><h1 className='sectionTitle ion-text-center ion-margin-top'>Account Selection</h1></IonText>
								<IonText> <div className='onboardingText'>Please choose your bank account from the list: </div></IonText>
							</IonCol>
						</IonRow>
					</IonGrid>
					<IonList>
						{bankAccounts?.map((bankAccount, index) => {
							return (
								<IonItem
									id={`bank_${bankAccount.accountNumber}`} key={bankAccount.accountNumber}>
									<IonGrid>
										<IonRow>
											<IonCol size='1'></IonCol>
											<IonCol size='10'>
												<IonText>
													<div className='accountList'>
														{bankAccount.name}
														<br />
														({bankAccount.accountHolderName})
														<br />
														{bankAccount.accountNumber} - {bankAccount.sortCode}
													</div>
												</IonText>
											</IonCol>
											<IonCol size='1'></IonCol>
										</IonRow>

										<IonRow>
											<IonCol sizeXs='2' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'></IonCol>
											<IonCol sizeXs='8' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4' >
												<IonButton color={"secondary"}
													className="accountSelectButton"
													onClick={() => chooseBankAccount(bankAccount)}>
													Choose Account
												</IonButton>
											</IonCol>
											<IonCol sizeXs='2' sizeSm='4' sizeMd='4' sizeLg='4' sizeXl='4'></IonCol>
										</IonRow>
									</IonGrid>
								</IonItem>
							);
						})}
					</IonList>
				</IonContent>
				: <PageSpinner />
			}
		</IonModal>
	</>;
}