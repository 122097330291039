import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonItemGroup, IonModal, IonRow, IonSpinner, IonText } from '@ionic/react';
import { syncOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { GetPaymentResult, Payment, PaymentMethod, PaymentStatusColor } from '../../lib';

export interface PaymentDetailsParams {
    payment: Payment | undefined,
    onClosed(): void
}

export default function PaymentDetailsModal(props: PaymentDetailsParams) {
    const [selectedPayment, setSelectedPayment] = useState<Payment>();
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    const onClickRefreshPayment = () => {
        setShowSpinner(true);
        getPaymentResult(selectedPayment?.id!);
    };

    const getPaymentResult = (paymentId: string) => GetPaymentResult(paymentId).then(response => {
        setSelectedPayment(response);
        setShowSpinner(false);
    });

    useEffect(() => {
        if (!props?.payment?.id) return;
        getPaymentResult(props.payment.id);
    }, [props.payment]);

    const closeModal = () => {
        // setSelectedPayment(undefined)
        props.onClosed();
    }

    let paymentMethod = "Unknown";

    if (selectedPayment !== undefined) {
        switch (selectedPayment.paymentMethod) {
            case PaymentMethod.NotAttempted:
                paymentMethod = "Not Attempted";
                break;
            case PaymentMethod.OpenBanking:
                paymentMethod = "Bank Transfer";
                break;
            case PaymentMethod.Card:
                paymentMethod = "Card Payment";
                break;
        }
    }

    return <>
        <IonModal className='paymentDetailsModal' backdropDismiss={false} isOpen={true}>
            {/* isOpen={typeof selectedPayment !== "undefined"}> */}
            <IonContent>
                <IonGrid class='selectedPaymentGrid'>

                    <IonRow>
                        <IonCol size='2'></IonCol>
                        <IonCol size='8'><div className='ion-text-center'>{showSpinner && <IonSpinner></IonSpinner>}</div></IonCol>
                        <IonCol size='2'>
                            <IonIcon
                                className='refreshIcon'
                                icon={syncOutline}
                                size='large'
                                onClick={onClickRefreshPayment}>
                            </IonIcon>
                        </IonCol>
                    </IonRow>
                    <IonItemGroup hidden={showSpinner}>
                        <IonRow>
                            <IonCol>
                                <IonText style={{ color: '#003466', textAlign: 'center', }}>
                                    <h1 className='selectedPaymentValue'>
                                        <b>
                                            <span className='selectedPaymentPoundSign'>{selectedPayment?.currencySymbol}</span>
                                            {selectedPayment?.amount}
                                        </b>
                                    </h1>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <p className='selectedPaymentText'>
                                        {selectedPayment
                                            ? (selectedPayment.clientDateCreated
                                                ? selectedPayment.clientDateCreated && new Date(selectedPayment.clientDateCreated).toLocaleString("en-GB")
                                                : selectedPayment.created.toLocaleString('en-GB'))
                                            : ''}
                                    </p>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>
                        <IonRow hidden={!selectedPayment?.orgName} className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='selectedPaymentText'><b>Subaccount: </b>{selectedPayment?.orgName}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>

                        <IonRow className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='selectedPaymentText'><b>Transaction ID: </b>{selectedPayment?.id}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>
                        <IonRow hidden={!selectedPayment?.preset} className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='selectedPaymentText'><b>Preset Name: </b>{selectedPayment?.preset?.name}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>
                        <IonRow hidden={!selectedPayment?.payerName} className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='selectedPaymentText'><b>Customer Name: </b>{selectedPayment?.payerName}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>
                        <IonRow hidden={!selectedPayment?.customerDetails} className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='selectedPaymentText'><b>Customer Details: </b>{selectedPayment?.customerDetails}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>
                        <IonRow hidden={!selectedPayment?.description} className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='selectedPaymentText'><b>Description:</b> {selectedPayment?.description}</h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>
                        <IonRow className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='selectedPaymentText'>
                                        <b>Payment Type: </b>
                                        {paymentMethod} </h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow>
                        {/* <IonRow className='selectedPayment'>
                            <IonCol size='1'></IonCol>
                            <IonCol size='10'>
                                <IonText style={{ color: '#003466', textAlign: 'left' }}>
                                    <h5 className='selectedPaymentText'><b>Invoice: </b> {selectedPayment?.invoiceId ? selectedPayment?.invoiceId : "Not yet invoiced"}  </h5>
                                </IonText>
                            </IonCol>
                            <IonCol size='1'></IonCol>
                        </IonRow> */}
                        <IonRow>
                            <IonCol className='selectedPayment'>
                                <IonText style={{ color: '#003466', textAlign: 'center', }}>
                                    <h4 className='statusOfPaymentTitle'><b>Status of Payment:</b></h4>
                                </IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonText style={{ textAlign: 'center', }}>
                                    <h4 className={PaymentStatusColor(selectedPayment)}><b>{selectedPayment?.status}</b></h4>
                                </IonText>
                            </IonCol>
                        </IonRow>
                    </IonItemGroup>
                    <IonRow>
                        <IonCol className="ion-text-center paymentHistoryDetailCloseButton">
                            <IonButton style={{ width: '120px' }} size="small" shape="round" color="secondary" onClick={closeModal}>Close &#187;</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonModal>
    </>;
}