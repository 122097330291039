import {
    InfiniteScrollCustomEvent, IonButton, IonCheckbox, IonCol, IonContent, IonDatetime, IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonLabel, IonList, IonModal, IonNote, IonRow, IonSelect, IonSelectOption, IonSpinner, IonText, IonToolbar
} from '@ionic/react';
import { format } from 'date-fns';
import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns/esm';
import { useContext, useEffect, useRef, useState } from 'react';
import { ListPayments, Payment, PaymentMethod, PaymentStatusColor } from '../../lib';
import { FormatDate } from '../../lib/util/Dates';
import { AccountContext } from '../../contexts/AccountContext';
import PaymentDetailsModal from './PaymentDetailsModal';
import { useLocation } from 'react-router';
import Routes from '../../Routes';

export type TransactionListProps = {
    subMerchantName?: string,
};

export default function TransactionList(props: TransactionListProps) {
    const location = useLocation();
    const [disableInfiniteScroll, setdisableInfiniteScroll] = useState<boolean>(false);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [selectedPayment, setSelectedPayment] = useState<Payment>();
    const [status, setStatus] = useState<string | undefined>();
    const [description, setDescription] = useState<string | undefined>();
    const [start, setStart] = useState<string | undefined>();
    const [end, setEnd] = useState<string | undefined>();
    const [dateStrings, setDateStrings] = useState<string[] | []>();
    const [showDateRangeModel, setShowDateRangeModel] = useState<boolean>(false);
    const [showPaymentHistorySpinner, setshowPaymentHistorySpinner] = useState<boolean>(false);
    const [rangeEnable, setRangeEnable] = useState<boolean>(false);
    const [justMyOwn, setJustMyOwn] = useState(false);
    const [paymentType, setPaymentType] = useState<string | undefined>();
    const accountContext = useContext(AccountContext);
    const lockApiCalls = useRef(false);
    const continuation = useRef<string | undefined>(undefined);

    var currentDateStrings = [] as string[];
    const getHistoryAsync = async (ev?: InfiniteScrollCustomEvent) => {
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        setshowPaymentHistorySpinner(true);

        var response = await ListPayments(
            continuation.current,
            50,
            status,
            description?.toLowerCase(),
            start,
            end,
            props.subMerchantName,
            justMyOwn,
            paymentType)
            .finally(() => lockApiCalls.current = false);

        setshowPaymentHistorySpinner(false);

        if ((response?.data?.length ?? 0) === 0) {
            if (response.continuation === null) setPayments([]);
            return;
        }

        (continuation?.current && continuation.current > '')
            ? setPayments([...payments!, ...response.data])
            : setPayments(response.data);

        continuation.current = response.continuation;

        if (payments.length >= 150) setdisableInfiniteScroll(true);
        if (ev) ev.target.complete();
    }

    useEffect(() => {
        if (!location.pathname.toLowerCase().startsWith(Routes.PaymentHistory.toLowerCase()) &&
            !(props.subMerchantName && location.pathname.toLowerCase().startsWith(Routes.SubMerchantList.toLowerCase() + '/'))) {
            return;
        }

        continuation.current = undefined;
        getHistoryAsync();
    }, [location.pathname, justMyOwn]);

    useEffect(() => {
        if (start && end && start === end) {
            setDateStrings([start]);
        }
    }, [start, end]);

    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const scrollToTop = () => {
        contentRef.current && contentRef.current.scrollToTop();
    };

    const onClickCancelModel = () => {
        setSelectedPayment(undefined);
        getHistoryAsync();
        scrollToTop();
    };

    const onClickDateRangeModel = () => {
        setShowDateRangeModel(false);
    };

    const closeDateRangeModel = () => {
        setShowDateRangeModel(false);
    }

    const setDatesFromOption = (dateCode: string) => {
        setRangeEnable(false);
        switch (dateCode) {
            case "1": // Today
                setStart(format(new Date(), "yyyy-MM-dd"));
                setEnd(format(addDays(new Date(), +1), "yyyy-MM-dd"));
                break;
            case "2": // Yesterday
                setStart(format(addDays(new Date(), -1), "yyyy-MM-dd"));
                setEnd(format(new Date(), "yyyy-MM-dd"));
                break;
            case "3": // This Week
                setStart(format(startOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"));
                setEnd(format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"));
                break;
            case "4": // Last Week
                setStart(format(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), -7), "yyyy-MM-dd"));
                setEnd(format(endOfWeek(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), -7), { weekStartsOn: 1 }), "yyyy-MM-dd"));
                break;
            case "5": // This Month
                setStart(format(startOfMonth(new Date()), "yyyy-MM-dd"));
                setEnd(format(endOfMonth(new Date()), "yyyy-MM-dd"));
                break;
            case "6": // Last Month
                setStart(format(addMonths(startOfMonth(new Date()), -1), "yyyy-MM-dd"));
                setEnd(format(endOfMonth(addMonths(startOfMonth(new Date()), -1)), "yyyy-MM-dd"));
                break;
            case "7": // Last 7 Days
                setStart(format(addDays(new Date(), -7), "yyyy-MM-dd"));
                setEnd(format(addDays(new Date(), +1), "yyyy-MM-dd"));
                break;
            case "8": // Last 30 Days
                setStart(format(addDays(new Date(), -30), "yyyy-MM-dd"));
                setEnd(format(addDays(new Date(), +1), "yyyy-MM-dd"));
                break;
            case "9": // Custom Range
                setRangeEnable(true);
                break;

            default: // All
                setStart(undefined);
                setEnd(undefined);
                break;
        }
    };

    const onClickSearchPaymentHistory = () => {
        continuation.current = undefined;
        getHistoryAsync();
    };

    const togglePaymentHistoryJustMyOwn = () => {
        if (justMyOwn === false) {
            setJustMyOwn(true);
        }
        else {
            setJustMyOwn(false);
        }
    };

    const onDescriptionChanged = (val: CustomEvent) => {
        setDescription(val.detail.value);
    };

    const onDateChanged = (val: CustomEvent) => {

        dateChange(val.detail.value);
        if (Array.isArray(val.detail.value)) {

            if (val.detail.value.length >= 1) {
                setStart(val.detail.value[0],);
                setEnd(val.detail.value[val.detail.value.length - 1]);
            }
        }
    };

    const onClickCancelPaymentTypeSelect = () => {
        setPaymentType(undefined);
    }

    const onClickCancelStatusSelect = () => {
        setStatus(undefined);
    };

    const onClickShowDateRangeModel = () => {
        if (rangeEnable) {
            setShowDateRangeModel(true);
        }
    };

    const dateChange = (selectedDate: string[]) => {

        if (selectedDate === undefined) {
            //setStart(undefined);
            //setEnd(undefined);
            currentDateStrings = [];
            setDateStrings(undefined);
            return;
        }

        if (selectedDate !== undefined && !Array.isArray(selectedDate)) return;

        var localDateStrings = selectedDate as string[];
        // Detect changes (prevents an infinite loop).
        if (currentDateStrings === localDateStrings) { return; }

        // Make sure we have at least two dates (otherwise nothing to do).
        if (!(localDateStrings && localDateStrings?.length > 1)) { return; }

        // Sort the dates so the earliest one is first.
        localDateStrings.sort((a, b) => {
            if (a > b) { return 1; }
            else if (a < b) { return -1; }
            else { return 0; }
        });

        // Revert to single selection (if required).
        if (dateStrings && dateStrings?.length > 2) {
            for (const d of dateStrings) {
                if (localDateStrings.indexOf(d) === -1) {
                    // Make sure this is not the first or last date (those are handled natively).
                    currentDateStrings = [d];
                    setDateStrings([d]);
                    return;
                    // No need to continue.
                }
            }

            for (const d of localDateStrings) {
                if (!dateStrings.find(val => val === d)) {
                    currentDateStrings = [d];
                    setDateStrings([d]);
                    return;
                    // No need to continue.
                }
            }
        }

        // Store the new value.
        const newValue = [];

        // Add all dates between the first and second dates.
        for (let d = new Date(localDateStrings[0]); d <= new Date(localDateStrings[localDateStrings.length - 1]); d.setDate(d.getDate() + 1)) {
            newValue.push(format(d, "yyyy-MM-dd"));
        }

        // Update the values at the end so the UI gets updated only once.
        currentDateStrings = newValue;
        setDateStrings(newValue);
        // Ref https://github.com/ionic-team/ionic-framework/issues/23572#issuecomment-1227543345
    }

    return <>
        <IonContent className='paymentHistoryBlock' ref={contentRef} scrollEvents={true}>
            <IonRow>
                <IonCol sizeXs='0' sizeSm='0' sizeMd='1' sizeLg='1' sizeXl='2'> </IonCol>
                <IonCol sizeXs='7' sizeSm='8' sizeMd='8' sizeLg='8' sizeXl='8'>
                    <IonInput

                        placeholder='Description'
                        className="descriptionInput"
                        onIonChange={onDescriptionChanged}
                        value={description}>
                    </IonInput>
                </IonCol>
                <IonCol sizeXs='3' sizeSm='3' sizeMd='3' sizeLg='1' sizeXl='1'>
                    <IonButton
                        className="searchButton"
                        shape="round"
                        color="secondary"
                        size="small"
                        onClick={onClickSearchPaymentHistory}>Search &#187;
                    </IonButton>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs='0' sizeSm='0' sizeMd='1' sizeLg='1' sizeXl='2'></IonCol>
                <IonCol sizeXs='10' sizeSm='8' sizeMd='8' sizeLg='8' sizeXl='10'> <div className="paymentHistoryfilterDiv">
                    <IonList>
                        <IonItem lines="none">
                            <IonSelect className='testFilter' placeholder="Status" interface="alert"
                                onIonChange={(e) => setStatus(e.detail.value)}
                                onIonCancel={onClickCancelStatusSelect}
                                value={status}>
                                <IonSelectOption value="">All</IonSelectOption>
                                <IonSelectOption value="100">Complete</IonSelectOption>
                                <IonSelectOption value="10">Pending</IonSelectOption>
                                <IonSelectOption value="200">Error</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonList>
                    <IonList>
                        <IonItem lines="none">
                            <IonSelect className='testFilter' placeholder="Date"
                                interface="alert"
                                onIonChange={(e) => setDatesFromOption(e.detail.value)}>
                                <IonSelectOption value="0">All</IonSelectOption>
                                <IonSelectOption value="1">Today</IonSelectOption>
                                <IonSelectOption value="2">Yesterday</IonSelectOption>
                                <IonSelectOption value="3">This Week</IonSelectOption>
                                <IonSelectOption value="4">Last Week</IonSelectOption>
                                <IonSelectOption value="5">This Month</IonSelectOption>
                                <IonSelectOption value="6">Last Month</IonSelectOption>
                                <IonSelectOption value="7">Last 7 Days</IonSelectOption>
                                <IonSelectOption value="8">Last 30 Days</IonSelectOption>
                                <IonSelectOption value="9">Custom Range</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonList>
                    <IonList>
                        <IonItem lines="none">
                            <IonSelect className='testFilter' placeholder="Type" interface="alert"
                                onIonChange={(e) => setPaymentType(e.detail.value)}
                                onIonCancel={onClickCancelPaymentTypeSelect}
                                value={paymentType}>
                                <IonSelectOption value="">All</IonSelectOption>
                                <IonSelectOption value="10">Open Banking</IonSelectOption>
                                <IonSelectOption value="20">Card Payment</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonList>
                </div>
                </IonCol>

            </IonRow>
            <IonRow>
                <IonCol sizeXs='0' sizeSm='0' sizeMd='1' sizeLg='1' sizeXl='2'></IonCol>
                <IonCol sizeXs='10' sizeSm='8' sizeMd='8' sizeLg='8' sizeXl='10'>
                    <div className="paymentHistoryJustMyOwnfilterDiv">
                        {(accountContext?.merchant?.submerchants?.length || 0) > 0 && !props.subMerchantName && <>
                            <IonList>
                                <IonItem id="submerchantTickBox" lines="none">
                                    <IonLabel id='submerchantTickBoxLabel'>Just my own payments&nbsp;</IonLabel>
                                    <IonCheckbox
                                        name="chkJustMyOwn"
                                        onIonChange={val => setJustMyOwn(val.detail.checked)}
                                        value={justMyOwn}
                                        onClick={togglePaymentHistoryJustMyOwn}>
                                    </IonCheckbox>
                                </IonItem>
                            </IonList>
                        </>}
                    </div>
                </IonCol>


            </IonRow>
            <IonRow>
                <IonCol sizeXs='1' sizeSm='1' sizeMd='1' sizeLg='2' sizeXl='3' ></IonCol>
                <IonCol sizeXs='5' sizeSm='5' sizeMd='5' sizeLg='4' sizeXl='3'>
                    <IonInput value={start ? format(new Date(start), "dd-MM-yyyy") : ''}
                        placeholder="From"
                        hidden={!rangeEnable}
                        disabled={!rangeEnable}
                        onClick={onClickShowDateRangeModel}
                        className="datePicker">
                    </IonInput>
                </IonCol>

                <IonCol sizeXs='5' sizeSm='5' sizeMd='5' sizeLg='4' sizeXl='3'>
                    <IonInput value={end ? format(new Date(end), "dd-MM-yyyy") : ''}
                        placeholder="To"
                        hidden={!rangeEnable}
                        disabled={!rangeEnable}
                        onClick={onClickShowDateRangeModel}
                        className="datePicker">
                    </IonInput>
                </IonCol>
                <IonCol sizeXs='1' sizeSm='1' sizeMd='1' sizeLg='2' sizeXl='3'></IonCol>

            </IonRow>
            <div className='ion-text-center'>{showPaymentHistorySpinner && <IonSpinner></IonSpinner>}</div>
            <IonGrid fixed>
                <IonList>

                    {payments?.map((payment, index) => {

                        let paymentMethod = "Unknown";

                        if (payment !== undefined) {
                            switch (payment.paymentMethod) {
                                case PaymentMethod.NotAttempted:
                                    paymentMethod = "Not Attempted";
                                    break;
                                case PaymentMethod.OpenBanking:
                                    paymentMethod = "Bank Transfer";
                                    break;
                                case PaymentMethod.Card:
                                    paymentMethod = "Card Payment";
                                    break;
                            }
                        }

                        return (

                            <IonItem className='paymentItem' id={`payment_${payment.id}`} key={payment.id} onClick={() => setSelectedPayment(payment)}>
                                <IonLabel>
                                    <h2 style={{ color: '#003466' }}><b>{payment.clientDateCreatedText ? payment.clientDateCreatedText : FormatDate(payment.created)}</b></h2>
                                    {payment.id && <p style={{ color: '#003466' }}>ID - {payment.id}</p>}
                                    {payment.orgName && <p style={{ color: '#003466' }}>Submerchant - {payment.orgName}</p>}
                                    {payment.payerName && <p style={{ color: '#003466' }}>Payer - {payment.payerName}</p>}
                                    {payment.customerDetails && <p style={{ color: '#003466' }}>Customer - {payment.customerDetails}</p>}
                                    {payment.preset && <p style={{ color: '#003466' }}>Preset - {payment.preset.name}</p>}
                                    {payment.description && <p style={{ color: '#003466' }}>Description - {payment.description}</p>}
                                    <p className={PaymentStatusColor(payment)}> {payment.status}</p>

                                </IonLabel>
                                <IonNote style={{ color: '#003466' }} slot='end'><h4> {payment.currencySymbol}{payment.amount.toFixed(2)}</h4></IonNote>
                            </IonItem>
                        );
                    })}

                    <IonInfiniteScroll threshold="200px" onIonInfinite={getHistoryAsync} disabled={disableInfiniteScroll}>
                        <IonInfiniteScrollContent loadingSpinner="circular" loadingText="Loading...">
                        </IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                </IonList>
            </IonGrid>
            <div className='paymentHistoryBlock'></div>
        </IonContent>

        {selectedPayment && <PaymentDetailsModal payment={selectedPayment} onClosed={onClickCancelModel} />}

        <IonModal handle={false} backdropDismiss={false} canDismiss={true} isOpen={showDateRangeModel} onDidDismiss={closeDateRangeModel} breakpoints={[0, 0.68]}
            initialBreakpoint={0.68} backdropBreakpoint={0.2}  >
            <IonContent className='dateTimeContent'>
                <br></br>
                <IonDatetime
                    className='datePicker'
                    multiple={true}
                    presentation="date"
                    value={dateStrings}
                    onIonChange={onDateChanged}>
                </IonDatetime>
                <IonButton style={{ width: '120px' }} size="small" shape="round" color="secondary" onClick={() => onClickDateRangeModel()}>Select</IonButton>
            </IonContent>
        </IonModal>
    </>;
}