import { IonButton, IonCol, IonGrid, IonImg, IonItem, IonList, IonRow, IonText } from '@ionic/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { GetQuerystringValue, Onboarding, OnboardingBankAccount, PostBankAccountStarted, SetOnboardingBankAccountAsync, StepNames, StepStatus } from '../../../lib';
import { ListBankAccountsFromRequestIdAsync, RequestAccountInformationServicesUrlAsync } from '../../../lib/data/Ais';
import { SubmitButton } from '../../controls/shared/Buttons';
import { RowWithDynamicGutter } from '../../controls/shared/Grid';
import Spinner from '../../controls/shared/Spinner';
import './BankAccount.css';
import { PageAndContent } from '../../controls/nav/PageControls';
import { useHistory } from 'react-router-dom';

export type Props = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function BankAccountStep(props: Props) {
    let accountInformationServiceCheck = props.onboarding.steps.get(StepNames.BankAccount);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [bankAccounts, setBankAccounts] = useState<OnboardingBankAccount[]>([]);
    const lockApiCalls = useRef(false);
    const history = useHistory();

    async function startAis() {
        if (lockApiCalls.current) return;
        lockApiCalls.current = true;

        try {
            await PostBankAccountStarted();
            var url = await RequestAccountInformationServicesUrlAsync(props.onboarding.businessDetails.countryCode, '/?ais=true');

            if (!url) {
                setErrorMessage("Could not start the bank account process");
                return; // alert?
            }

            window.location.href = url;
        } catch (error) {
            setErrorMessage("Could not start the bank account process");
        } finally {
            lockApiCalls.current = false;
        }
    };

    async function chooseBankAccount(bankAccount: OnboardingBankAccount) {
        if (lockApiCalls.current) return;
        lockApiCalls.current = true;

        try {
            await SetOnboardingBankAccountAsync(bankAccount);
            props.onNext();
        } catch (error) {
            setErrorMessage("Could not start the bank account process");
        } finally {
            lockApiCalls.current = false;
        }
    };

    async function mainProcess() {
        if (!accountInformationServiceCheck) return;
        if (lockApiCalls.current) return;

        if (accountInformationServiceCheck?.status >= StepStatus.Started) {
            let requestId = GetQuerystringValue("request-id");

            if (requestId) {
                lockApiCalls.current = true;
                var bankAccountResponse: OnboardingBankAccount[] = [];

                try {
                    bankAccountResponse = await ListBankAccountsFromRequestIdAsync(requestId);
                } catch (error) {
                    setErrorMessage("Could not get the bank account list");
                } finally {
                    lockApiCalls.current = false;
                }

                if (bankAccountResponse.length === 1) {
                    await chooseBankAccount(bankAccountResponse[0]);
                    history.replace({ search: '' });
                    return;
                }

                setBankAccounts(bankAccountResponse);
                history.replace({ search: '' });
                return;
            }

            // if there is no request-id but this is "started" it must be a refresh and we'll have to try again
            startAis();
        }
    }

    useEffect(() => {
        mainProcess();
    }, []);

    function goBack() {
        props.onNext()
    }

    const multipleAccounts = <>
        <PageAndContent>
            <IonGrid>
                <IonRow style={{marginBottom: '-25px'}}>
                    <IonCol>
                        <IonText><h1 className='sectionTitle ion-text-center ion-margin-top'>Account Selection</h1></IonText>
                        <IonText> <div className='onboardingText'>Please choose your bank account from the list: </div></IonText>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonList>
                {bankAccounts?.map((bankAccount, index) => {
                    return (
                        <IonItem
                            id={`bank_${bankAccount.accountNumber}`} key={bankAccount.accountNumber}>
                            <IonGrid>
                                <RowWithDynamicGutter>
                                    <IonText>
                                        <div className='accountList'>
                                            {bankAccount.name}
                                            <br />
                                            ({bankAccount.accountHolderName})
                                            <br />
                                            {bankAccount.accountNumber ? <>
                                                {bankAccount.accountNumber} - {bankAccount.sortCode}
                                            </> : <>
                                                {bankAccount.iban}
                                            </>}
                                        </div>
                                    </IonText>
                                    <IonButton color={"secondary"}
                                        className="accountSelectButton"
                                        onClick={() => chooseBankAccount(bankAccount)}> Choose Account &#187;
                                    </IonButton>
                                </RowWithDynamicGutter>
                            </IonGrid>
                        </IonItem>
                    );
                })}
            </IonList>
        </PageAndContent>
    </>;

    const content = <>
        <PageAndContent>
            <IonGrid>
                <RowWithDynamicGutter>
                    <IonImg className='smallLogo' src='./assets/img/logo.png' />
                    <h1 className='sectionTitle'>Bank Account Selection</h1>
                    <p className='onboardingSubText'>On the next screen you will be asked to select the bank account you wish to use, the chosen account will:</p>
                    <ul className='documentList'>
                        <li>Receive Payments</li>
                        <li>Pay for transaction fees</li>
                    </ul>
                    <div className="bottomButtonContainer">
                        <div className="bottomButtonRow">
                            <IonButton onClick={goBack}>Go Back &#187;</IonButton>
                            <SubmitButton
                                onClick={startAis}
                                text="Next &#187;" />
                        </div></div>
                    <p style={{ color: "#ff7100", fontSize: '13px' }} className='ion-text-center'>
                        <i>Your bank account information will not be shared with any unauthorised third parties</i>
                    </p>
                </RowWithDynamicGutter>
            </IonGrid>
        </PageAndContent>
    </>;

    function render() {
        if (errorMessage) return <p>{errorMessage}</p>;
        if (bankAccounts.length > 1) return multipleAccounts;
        if (accountInformationServiceCheck && accountInformationServiceCheck.status >= StepStatus.Started) return <Spinner />;
        return content;
    }

    return <>
        {render()}
    </>;
}