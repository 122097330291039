import { IonButton, IonCheckbox, IonContent, IonImg, IonItem, IonPage, useIonRouter } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { CardApplication, CardsStatus, GetCardsApplication, StartCardsApplication } from "../../../lib";
import { ButtonRef, SubmitButton } from "../../controls/shared/Buttons";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";
import PageSpinner from "../../controls/shared/PageSpinner";
import Spinner from "../../controls/shared/Spinner";
import { OnboardLinkShare } from "../../onboarding/controls/OnboardLinkSharing";
import { AccountContext } from "../../../contexts/AccountContext";
import { Redirect } from "react-router";
import { PageAndContent } from "../../controls/nav/PageControls";

export default function RequestCardsPage() {
    const accountContext = useContext(AccountContext);
    const [application, setApplication] = useState<CardApplication>();
    const [error, setError] = useState<string>();
    const lockApiCalls = useRef(false);
    const router = useIonRouter();
    const buttonRef = useRef<ButtonRef>(null);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    async function onApplyCardsClick() {
        try {
            var result = await StartCardsApplication();
            if (!result) setError("An error occurred");
            getApplication();
        }
        catch {
            setError("There was an error creating the credit card application");
        }
    }

    async function getApplication() {
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        try {
            var response = await GetCardsApplication();
            setApplication(response);
            buttonRef.current?.stopSpinning();
        }
        finally {
            lockApiCalls.current = false;
            buttonRef.current?.stopSpinning();
        }
    }

    useEffect(() => {
        getApplication();
    }, []);

    function goHome() {
        accountContext?.refresh();
        router.goBack();
    }

    if (accountContext?.merchant?.paymentMethods?.ryft?.suppressed) {
        return <Redirect to="/" />;
    }

    const errorView = <>
        <RowWithDynamicGutter>
            <p className="onboardingText">
                {error}
            </p>
            <div className="ion-margin-top">
                <IonButton onClick={() => router.goBack()}>Go Back &#187;</IonButton>
            </div>
        </RowWithDynamicGutter>
    </>;

    const notRequested = <>
        <RowWithDynamicGutter>
            <h1 className="sectionTitle ion-margin-bottom">Card Payments Setup</h1>

        </RowWithDynamicGutter>
        <RowWithDynamicGutter>
            <p className="onboardingSubText">
                Card payments are subject to transaction fees of 0.99%.
            </p>
            {accountContext?.merchant?.currency === "EUR"
                ?
                <p className="onboardingSubText" >
                    Once you have reached a payout amount of {accountContext?.merchant?.companyType !== "soleTrader" ? "€1" : "€2500"} &#40;after fees&#41;, your payout will be suspended until all KYC checks are complete.
                </p>
                :
                <p className="onboardingSubText" >
                    Once you have reached a payout amount of {accountContext?.merchant?.companyType !== "soleTrader" ? "£1" : "£2500"} &#40;after fees&#41;, your payout will be suspended until all KYC checks are complete.
                </p>
            }
            <IonItem lines="none">

                <IonCheckbox slot="start" onIonChange={e => setCanSubmit(e.detail.checked)} />&nbsp;
                <p className="onboardingSubText"> Tick this box to indicate that you agree to the
                    <a href="https://ryftpay.com/terms-conditions" target="_blank" rel="noreferrer"><b> Terms of Service</b></a></p>
            </IonItem>
            <div>
                <SubmitButton
                    ref={buttonRef}
                    text="Setup »"
                    onClick={() => onApplyCardsClick()}
                    disabled={!canSubmit}
                />
                <IonButton onClick={() => router.goBack()}>Take me Home &#187;</IonButton>
            </div>
        </RowWithDynamicGutter>
    </>;

    const approved = <>
        <RowWithDynamicGutter>

            <h1 className='mainHeader'>Card Payments</h1>
            <p className="onboardingText">
                Your customers now have the option to pay you by card.
            </p>
            <div>
                <SubmitButton
                    ref={buttonRef}
                    text="Take me Home »"
                    onClick={() => goHome()}
                />
            </div>

        </RowWithDynamicGutter>
    </>;

    const failed = <>
        <RowWithDynamicGutter>

            <h1 className='mainHeader'>Card Payments</h1>
            <p className="onboardingText">
                There was an issue with your onboarding, a member of the team will be in touch to assist.
            </p>
            <div>
                <SubmitButton
                    ref={buttonRef}
                    text="Take me Home »"
                    onClick={() => goHome()}
                />
            </div>

        </RowWithDynamicGutter>
    </>

    // offer to create onboard sessions for directors not already on the application
    const inviteDirectors = <>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
            <RowWithDynamicGutter>
                <h1 className='sectionTitle'>Invite For Verification</h1>

                <p className="onboardingSubText">
                    We need 1 director from the list below to verify their identity.
                </p>

            </RowWithDynamicGutter>
            {application?.directorsAvailable?.map(person => <OnboardLinkShare
                key={person.id}
                person={{
                    id: person.id,
                    name: person.name,
                    address: person.address,
                    shareLink: person.shareLink,
                    onboardSession: person.onboardSession,
                }} />)}

            <RowWithDynamicGutter>
                <IonButton color={'secondary'} onClick={() => goHome()}>
                    Take me Home »
                </IonButton>
            </RowWithDynamicGutter>
        </div>
    </>;

    const contentSelector = () => {
        if (error) return errorView;
        if (application?.status === CardsStatus.NotRequested) return notRequested;
        if ((application?.status === CardsStatus.VerificationRequired && application.directorsRemaining === 0)
            || (application?.status === CardsStatus.VerificationSubmitted)) return approved;
        if (application?.status === CardsStatus.VerificationRequired && application.directorsRemaining + application.directorsAdded > 0) return inviteDirectors;
        if (accountContext?.merchant?.paymentMethods?.ryft?.available || application?.status === CardsStatus.AccountCreated) return approved;
        if (application?.status === CardsStatus.VerificationFailed) return failed;
        return <Spinner />;
    };

    return application ? <>
        <PageAndContent contentStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <RowWithDynamicGutter>
                <IonImg className="smallLogo" src='./assets/img/logo.png' />
            </RowWithDynamicGutter>
            {contentSelector()}
        </PageAndContent>
    </> : <PageSpinner />;
}