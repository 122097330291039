import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCol, IonGrid, IonRow, IonText,
    useIonRouter
} from '@ionic/react';

import { useContext } from 'react';
import { Merchant } from '../lib';
import { AccountContext } from '../contexts/AccountContext';
import './Home.css';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { RowWithSmallGutters } from './controls/shared/Grid';
import TakePayment from './payments/TakePayment';
import React from 'react';
import { PageAndContent } from './controls/nav/PageControls';
import Routes from '../Routes';

<style>
    {`
          button {
            background-color: blue;
            color: white;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
          }
        `}
</style>
type Props = {
    merchant: Merchant,
    refreshMerchant: Function,
};

export default function Home(props: Props) {
    const accountContext = useContext(AccountContext);
    const router = useIonRouter();

    function handleReauthoriseAccessTokenClick() {
        router.push(Routes.TransactionMonitoringPage);
    }

    function buildOtherWaysToPaySnippet(displayText: string, url: string) {
        return <IonButton
            style={{ minimumWidth: "auto", padding: "0 8px", margin: "0 -5px" }}
            routerLink={url}>
            {displayText} &#187;
        </IonButton>;
    }

    function buildOtherWaysToPayPanel(showStandingOrders: boolean) {
        return <>
            <RowWithSmallGutters colClassName='ion-text-center'>
                <IonText style={{ fontSize: '18px', color: '#003466', fontWeight: 'bold' }}> Other Ways To Take Payments</IonText>

                <div className='ion-margin-top' style={{ display: "flex", justifyContent: "center", width: "auto" }}>
                    {buildOtherWaysToPaySnippet('Preset Payments', '/tabs/paymentpresets')}
                    {showStandingOrders && buildOtherWaysToPaySnippet('Standing Orders', '/tabs/standingorders')}
                </div>
            </RowWithSmallGutters>
        </>;
    }

    const standingOrdersDisconnectedMessage = <>
        <IonRow style={{
            justifyContent: 'center',
            backgroundColor: 'white',
            zIndex: 10,
            marginTop: '20px',
        }}>
            <IonCol sizeXs='12' sizeSm='12' sizeMd='8' sizeLg='6' sizeXl='4'>
                <IonCard id='cardQuestion'>
                    <IonCardContent>
                        <div style={{ textAlign: 'center' }}>
                            <IonText style={{ textAlign: 'center', color: '#003466', fontSize: '20px' }} className='textRow'>Bank Token Disconnected</IonText>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <IonText style={{ textAlign: 'center', color: '#003466', fontSize: '16px', fontWeight: '500' }} className='textRow'>Click to reconnect</IonText>
                        </div>
                        <IonRow style={{ justifyContent: 'center', marginTop: '5px' }}>
                            <IonButton color={'secondary'} style={{ maxWidth: '200px' }} onClick={handleReauthoriseAccessTokenClick}>Reconnect Now &#187;</IonButton>
                        </IonRow>
                    </IonCardContent>
                </IonCard>
            </IonCol>
        </IonRow>
    </>;

    const standingOrdersDisconnectionCloseMessage = <>
        <IonRow style={{
            justifyContent: 'center',

            backgroundColor: 'white',
            zIndex: 10,
            marginTop: '20px',
        }}>
            <IonCol sizeXs='12' sizeSm='12' sizeMd='8' sizeLg='6' sizeXl='4'>
                <IonCard id='cardQuestion'>
                    <IonCardContent>
                        <div style={{ textAlign: 'center' }}>
                            <IonText style={{ textAlign: 'center', color: '#003466', fontSize: '16px', fontWeight: '700' }}>
                                Your bank token is going to disconnect in {accountContext?.merchant?.paymentMethods.tokenIo.daysUntilAccessTokenExpiry} days
                            </IonText>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '5px' }}>
                            <IonText style={{ textAlign: 'center', color: '#003466', fontSize: '16px', fontWeight: '500' }} className='textRow'>Click to reconnect</IonText>
                        </div>
                        <IonRow style={{ justifyContent: 'center', marginTop: '5px' }}>
                            <IonButton color={'secondary'} style={{ maxWidth: '200px' }} onClick={handleReauthoriseAccessTokenClick}>Reconnect &#187;</IonButton>
                        </IonRow>
                    </IonCardContent>
                </IonCard>
            </IonCol>
        </IonRow>
    </>;

    function selectMessagesToDisplay(): React.ReactNode[] {
        var snippets: React.ReactNode[] = [];

        if (!accountContext?.merchant?.settings) return snippets;

        if (accountContext.merchant?.paymentMethods?.tokenIo?.enabled &&
            accountContext.merchant.paymentMethods.tokenIo.standingOrdersSupported === true &&
            accountContext.merchant.paymentMethods.tokenIo.daysUntilAccessTokenExpiry !== undefined) {

            if (accountContext.merchant.paymentMethods.tokenIo.standingOrdersMonitored &&
                accountContext.merchant.paymentMethods.tokenIo.daysUntilAccessTokenExpiry < 1) {
                return [standingOrdersDisconnectedMessage];
            }

            if (accountContext.merchant.paymentMethods.tokenIo.standingOrdersMonitored &&
                accountContext.merchant.paymentMethods.tokenIo.daysUntilAccessTokenExpiry < 8) {
                return [standingOrdersDisconnectionCloseMessage];
            }

            snippets.push(buildOtherWaysToPayPanel(true));
        }
        else {
            snippets.push(buildOtherWaysToPayPanel(false));
        }

        return snippets;
    }

    return <>
        <PageAndContent contentClassName='takePaymentContent'>
            <IonGrid style={{ height: '100%' }}>
                {TakePayment()}

                {selectMessagesToDisplay().map((snippet, index) => (
                    <React.Fragment key={index}>
                        {snippet}
                    </React.Fragment>
                ))}
            </IonGrid>
        </PageAndContent>
    </>;
}
