import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonModal, IonRouterLink, IonRow, IonSpinner, IonText, IonToggle, useIonRouter } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { CreatePaymentPreset, DeletePaymentPreset, PaymentPresetDesign, PaymentPreset, UpdatePaymentPreset, PaymentPresetPayloadFlags } from "../../lib/data/PaymentPresets";
import { ButtonRef, SubmitButton } from "../controls/shared/Buttons";
import { GlobalToastContext } from '../controls/shared/GlobalToastProvider';
import { RowWithDynamicGutter, RowWithSmallGutters } from "../controls/shared/Grid";
import { TextInput } from "../controls/shared/Inputs";
import _ from 'lodash';
import AmountInput from "./components/AmountsInput";
import { AccountContext } from "../../contexts/AccountContext";
import { OrgTypes, VatRate } from "../../lib";
import VatRateSelectionControl from "../controls/shared/VatRateSelection";
import ShareUrlButton from "../controls/shared/ShareUrlButton";
import { PageAndContent } from "../controls/nav/PageControls";

export default function PaymentPresetPage() {
    const globalToastContext = useContext(GlobalToastContext);
    const accountContext = useContext(AccountContext);
    const location = useLocation(); // Get the location object
    const passedState = location.state as PaymentPreset & { editMode?: boolean };  // Cast state to include editMode
    const [name, setName] = useState<string | undefined>(passedState?.name);
    const [flags, setFlags] = useState<PaymentPresetPayloadFlags>(passedState?.payload?.flags || {});
    const [amounts, setAmounts] = useState<number[]>(passedState?.payload?.amounts || []);
    const [design, setDesign] = useState<PaymentPresetDesign>(passedState?.design || {});
    const [vatRate, setVatRate] = useState<VatRate | undefined>(passedState?.vatRate);
    const buttonRef = useRef<ButtonRef>(null);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [editMode, setEditMode] = useState(passedState?.editMode || !passedState); // Default to true if no state or editMode is true in state
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const router = useIonRouter();

    useEffect(() => {
        if (!design) return;
        // if we're in edit mode (passedState != null) then enable the save button when something changes
        // otherwise it only needs a name to be saved
        if (passedState) setSaveButtonDisabled((
            _.isEqual(passedState, {
                name,
                payload: {
                    flags,
                    amounts,
                },
                design,
            })
        ))
        else setSaveButtonDisabled(!(name));
    }, [flags, amounts, design, name, passedState]);

    function updatePayloadFlag<K extends keyof PaymentPresetPayloadFlags>(
        property: K,
        value: PaymentPresetPayloadFlags[K]) {
        setFlags(prev => ({
            ...prev,
            [property]: value,
        }));
    };

    function updateDesign<K extends keyof PaymentPresetDesign>(
        property: K,
        value: PaymentPresetDesign[K]) {
        setDesign(prev => ({
            ...prev,
            [property]: value,
        }));
    };

    async function savePaymentPreset() {
        if (!design || !name) return;

        try {
            // clean the data
            // remove any empty or zero amounts
            const cleanAmounts = amounts.filter(a => a > 0);

            const savedData = passedState
                ? await UpdatePaymentPreset({ ...passedState, name, payload: { flags, amounts: cleanAmounts }, design, id: passedState?.id, vatRate })
                : await CreatePaymentPreset(name, { flags, amounts: cleanAmounts }, design, vatRate);

            setName(savedData.name);
            setFlags(savedData.payload.flags);
            setAmounts(savedData.payload.amounts);
            setVatRate(savedData.vatRate);
            setDesign(savedData.design);

            if (!passedState) router.goBack();
        } catch (e) {
            globalToastContext.error("There was a problem creating the payment preset. Please try again or contact us for support.");
            console.log(e);
        }
        finally {
            setEditMode(false);
        }
    }

    async function deletePreset() {
        try {
            setIsDeleting(true);
            await DeletePaymentPreset(passedState.id);
            goBack();
        }
        finally {
            setShowDeleteModal(false);
            setIsDeleting(false);
        }
    }

    function goBack() {
        router.goBack();
    }

    const editForm = <>

        <RowWithSmallGutters colClassName="greyPanel">
            <TextInput
                onChange={(val) => setName(val)}
                value={name}
                label="Payment Preset Name"
                maxlength={30}
                readOnly={editMode === false} />

            <TextInput
                onChange={(val) => updateDesign("title", val!)}
                value={design.title}
                label="Title Text"
                maxlength={30}
                readOnly={editMode === false} />

            <TextInput
                onChange={(val) => updateDesign("subTitle", val!)}
                value={design.subTitle}
                label="Subheading Text (optional)"
                maxlength={100}
                readOnly={editMode === false} />
        </RowWithSmallGutters>

        <RowWithSmallGutters colClassName="greyPanel">
            <h2 className="panelTitle">Set Payment Amounts</h2>

            <div style={{ color: '#003466', marginBottom: '15px' }}>
                Custom Amount Entry
                <IonToggle disabled={editMode === false} style={{ float: 'right', bottom: "5px" }} checked={flags.allowCustomAmount}
                    onIonChange={val => updatePayloadFlag("allowCustomAmount", val.detail.checked)}>
                </IonToggle>
                <div style={{ fontSize: '13px', color: '#003466' }}>Enable this to give your payers the option to enter their own amount.</div>
            </div>

            <div style={{ color: '#003466', fontWeight: '16px' }}>Amount</div>

            <div style={{ fontSize: '13px', color: '#003466', }}>
                Fill in more than one box to create payment amounts.
                <div style={{ marginTop: "10px" }}>
                    <AmountInput readOnly={editMode === false} amounts={amounts} setAmounts={setAmounts} />
                </div>
            </div>
        </RowWithSmallGutters>

        {accountContext?.merchant?.vatRates && accountContext?.merchant?.vatRates.length > 0 &&
            <RowWithSmallGutters>
                <VatRateSelectionControl
                    readonly={editMode === false}
                    expandable={false}
                    objectsAffectedText="payments requested using this preset"
                    selectedVatRate={vatRate || accountContext.merchant.vatRates.find(rate => rate.isDefault)}
                    vatRates={accountContext.merchant.vatRates}
                    onVatRateSelected={rate => setVatRate(rate)} />
            </RowWithSmallGutters>}

        <RowWithSmallGutters colClassName="greyPanel">
            <div style={{ color: '#003466', marginBottom: '15px' }}>
                Collect Payer Name
                <IonToggle disabled={editMode === false} style={{ float: 'right', bottom: "5px" }} checked={flags.allowPayerName}
                    onIonChange={val => updatePayloadFlag("allowPayerName", val.detail.checked)}>
                </IonToggle>
                <div style={{ fontSize: '13px', color: '#003466' }}>Enable this to collect your payer's name on the payment screen.</div>
            </div>

            <div style={{ color: '#003466', marginBottom: '15px' }}>
                Comments Box
                <IonToggle disabled={editMode === false} style={{ float: 'right', bottom: "5px" }} checked={flags.allowDescription}
                    onIonChange={val => updatePayloadFlag("allowDescription", val.detail.checked)}>
                </IonToggle>
                <div style={{ fontSize: '13px', color: '#003466' }}>Enable this to add a comments box to the payment screen.</div>
            </div>
        </RowWithSmallGutters>

        {accountContext?.merchant?.currency === "GBP" &&
            (accountContext?.merchant?.companyType === OrgTypes.Charity ||
                accountContext?.merchant?.companyType === OrgTypes.Foundation ||
                accountContext?.merchant?.companyType === OrgTypes.CIO) && (
                <RowWithSmallGutters colClassName="greyPanel">
                    <div style={{ color: '#003466', marginBottom: '15px' }}>
                        Gift Aid
                        <IonToggle disabled={editMode === false} style={{ float: 'right', bottom: "5px" }} checked={flags.allowGiftAid}
                            onIonChange={val => updatePayloadFlag("allowGiftAid", val.detail.checked)}>
                        </IonToggle>
                        <div style={{ fontSize: '13px', color: '#003466' }}>Enable this to allow your donors to provide their details so you can claim Gift Aid.</div>
                    </div>
                </RowWithSmallGutters>)}
    </>;

    const headerRightButtons = <>
        <div style={{ position: 'absolute', bottom: '8px', right: '15px' }}>
            {editMode && <IonButton
                color={'#fff'}
                className="circularButton"
                disabled={saveButtonDisabled}
                onClick={() => savePaymentPreset()}>
                <IonIcon
                    className="circularButtonContent"
                    src="assets\icon\checkmark.svg"
                />
            </IonButton>}
            {!editMode && <IonButton
                className="circularButton"
                style={{ backgroundColor: 'none' }}
                onClick={() => setEditMode(true)}
                color={'#fff'}>
                <IonIcon
                    className="circularButtonContent"
                    src="assets\icon\penIcon.svg" />
            </IonButton>}
            <IonButton onClick={() => setShowDeleteModal(true)} className="circularButton" color={'#fff'}>
                <IonIcon style={{ paddingRight: '0px' }} className="circularButtonContent" src="assets\icon\bin.svg" />
            </IonButton>
        </div>
    </>;

    function drawDeleteModal() {
        return (
            <IonModal className='smallModal' backdropDismiss={true} isOpen={true}>
                <IonContent className="modelContent">
                    <IonText style={{ color: '#003466', textAlign: 'center' }}>
                        <b>
                            <div style={{ fontSize: '17px', marginTop: '8px' }}>
                                Are you sure you want to delete this preset?
                            </div>
                        </b>
                        <p style={{ fontWeight: '500' }}>This action cannot be undone</p>
                    </IonText>
                    <IonRow style={{ textAlign: 'center' }}>
                        <IonCol style={{ marginLeft: '-10px' }} className="bottomColButton" onClick={() => setShowDeleteModal(false)}>
                            <IonText style={{ fontSize: '17px', fontWeight: '400' }}>No</IonText>
                        </IonCol>
                        <IonCol style={{ marginRight: '-10px' }} className="bottomColButton" onClick={deletePreset}>
                            {isDeleting === true
                                ? <div><IonSpinner style={{ marginLeft: '-15px', color: '#fff' }} /></div>
                                : <IonText style={{ fontSize: '17px', fontWeight: '400' }}>Yes</IonText>}
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
        );
    }

    function drawShareModal() {
        return (
            <IonModal backdropDismiss={true} isOpen={true}>
                <IonContent>
                    <IonRow className='modalTitleRow'>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"></IonCol>
                        <IonCol sizeXs="10" sizeSm="8" sizeMd="6" sizeLg="6" sizeXl="6" class='ion-text-center'>
                            <IonText color='primary'>
                                <h1 className='scanBelowText'>Scan below to share preset</h1>
                            </IonText>
                        </IonCol>
                        <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"> </IonCol>
                        <IonCol sizeXs="10" sizeSm="8" sizeMd="6" sizeLg="6" sizeXl="6">
                            <br />
                            <IonImg src={`/api/qrcode?logo=tt1&content=${passedState?.shareUrl}`} />
                            <br />
                            <ShareUrlButton url={passedState?.shareUrl} buttonText="Share PayLink »" />
                        </IonCol>
                        <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3" > </IonCol>
                    </IonRow>

                    <div className="ion-text-center" >
                        <IonRouterLink
                            className='closeModal'
                            style={{ textDecoration: "underline" }}
                            onClick={() => setShowShareModal(false)}>
                            <b>Back</b>
                        </IonRouterLink>
                    </div>
                </IonContent>
            </IonModal>
        );
    }

    const toolbarChildren = <>
        <div style={{ position: 'absolute', bottom: '8px', left: '15px' }}>
            <IonButton onClick={goBack} className="circularButton">
                <IonIcon className="circularButtonContent" src="assets\icon\chevron-back-outline.svg" />
            </IonButton>
        </div>
        <h2 className="titleBarText"> {passedState ? "Payment Preset" : "Create Preset"}</h2>

        {passedState && headerRightButtons}
    </>;

    return <>
        <PageAndContent toolbarChildren={toolbarChildren}>
            <IonGrid>
                {editForm}

                {!passedState &&
                    <RowWithSmallGutters>
                        <SubmitButton
                            disabled={saveButtonDisabled}
                            text="Save Preset &#187;" onClick={() => savePaymentPreset()} />
                    </RowWithSmallGutters>}

                {passedState && <>
                    <RowWithDynamicGutter>
                        <SubmitButton
                            colour="secondary"
                            onClick={() => {
                                setShowShareModal(true);
                                buttonRef.current?.stopSpinning();
                            }}
                            text="Take Payment &#187;"
                            ref={buttonRef}
                            disabled={editMode} />
                    </RowWithDynamicGutter>
                </>}
            </IonGrid>
        </PageAndContent >

        {showShareModal && drawShareModal()}

        {showDeleteModal && drawDeleteModal()}
    </>;
}