import { ApiGet, ApiPost } from '..';

export type Merchant = {
    accountNumber: string,
    sortCode: string,
    accessTokenAvailable: string,
    vatnumber: string,
    accountHolderName: string,
    logoType: string | undefined,
    redirectButtonText: string,
    redirectURL: string,
    submerchants: SubMerchant[],
    settings: MerchantSettings,
    paymentMethods: MerchantPaymentMethods,
    companyType: string,
    vatRates: VatRate[],
    currency: string, // derived from bank account when available
    currencySymbol: string,
    countryCode: string,
}

export type VatRate = {
    rate: number | undefined,
    name: string,
    isDefault: boolean,
};

export type BankAccount = {
    externalId: string | null,
    name: string,
    accountHolderName: string | null,
    accountNumber: string | null,
    sortCode: string | null,
    iban: string | null,
    bankId: string | null,
    bankCode: string | null, // only available on merchants
    bic: string | null,
    identifier: string | null,
    status: string | null,
    type: string | null,
    currency: string | null,
};

export type VatNumberRequest = {
    vatNumber: string,
};

export type MerchantSettings = {
    redirectButtonText: string,
    redirectURL: string,
    previewFeatures: boolean,
};

export type SubMerchant = {
    name: string,
    status: string,
    accounts: MerchantAccount[],
    inviteUrl: string,
};

export type MerchantAccount = {
    accountId: string,
}

export interface MerchantPaymentMethods {
    tokenIo: MerchantTokenIoPaymentMethod;
    ryft: MerchantRyftPaymentMethod;
    worldPay: MerchantWorldPayPaymentMethod;
}

export interface MerchantTokenIoPaymentMethod {
    enabled: boolean;
    standingOrdersSupported: boolean;
    standingOrdersMonitored: boolean;
    daysUntilAccessTokenExpiry?: number; 
}

export interface MerchantRyftPaymentMethod {
    suppressed: boolean;
    available: boolean,
    enabled: boolean;
    amexEnabled: boolean;
    amexAvailable: boolean;
}

export interface MerchantWorldPayPaymentMethod {
    enabled: boolean;
}

export const GetMerchantAsync = async (): Promise<Merchant | null> => {
    var response = await ApiGet('GetMerchant');

    if (response.status === 200) {
        return response.data;
    }

    return null;
};

export const SetBankAccountAsync = async (bankAccount: BankAccount) => {
    var response = await ApiPost("PostBankAccount", bankAccount);

    return response.status === 200;
};

export const SetVatNumberAsync = async (vatNumber: string): Promise<VatNumberRequest> => {
    var response = await ApiPost("PostVatNumber", { vatNumber });

    if (response.status === 200) {
        return { vatNumber };
    }

    return { vatNumber };
};

export const SetRedirectUrlAsync = async (redirectButtonText: string, redirectURL: string) => {
    var response = await ApiPost("SetRedirectUrlAsync", { redirectButtonText, redirectURL });

    if (response.status === 200) {
        return true;
    }

    return false;
};

export const PostLogoAsync = async (logoFile: FormData): Promise<string> => {
    var response = await ApiPost("UploadLogo", logoFile, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }
    );

    if (response.status === 200) {
        return '';
    }

    return '';
};

export const GetLogoAsync = async (logoType: string): Promise<string> => {
    var response = await ApiGet(logoType);

    if (response.status === 200) {
        return response.data;
    }

    return '';
}

export async function CreateSubMerchant(name: string): Promise<SubMerchant | null> {
    var response = await ApiPost("CreateSubMerchant", { name });

    if (response.status === 200) {
        return response.data;
    }

    return null;
}

export async function SendSubMerchantInviteEmail(subMerchantName: string, emailAddress: string) {
    var response = await ApiPost("SendSubMerchantEmailInvitation", { subMerchantName, emailAddress });

    return response.status === 200;
}

export async function SetCardPaymentsEnabled(enabled: boolean, amexEnabled: boolean) {
    var response = await ApiPost("SetCardPaymentsEnabled", { enabled, amexEnabled });

    return response.status === 200;
}

export async function SetMerchantVatRatesAsync(vatRates: VatRate[]) {
    var response = await ApiPost("SetMerchantVatRates", vatRates);
    return response.status.toString().startsWith('2');
}