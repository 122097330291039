import { IonButton, IonGrid, IonImg, IonInput, IonRow, IonSelect, IonSelectOption } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { AboutBusinessQuestions, Onboarding, SaveAboutBusiness } from "../../../lib";
import { ButtonRef, SubmitButton } from "../../controls/shared/Buttons";
import { RowWithDynamicGutter } from "../../controls/shared/Grid";
import { PageAndContent } from "../../controls/nav/PageControls";

type Props = {
    onboarding: Onboarding,
    onNext: Function,
};

export default function AboutYourBusinessStep(props: Props) {
    const [aboutBusiness, setAboutBusiness] = useState<AboutBusinessQuestions>({
        companyName: props.onboarding.businessDetails.companyName,
        website: props.onboarding.businessDetails.website || '',
        phoneNumber: '', // Initial phone number is empty
        applicantRole: props?.onboarding?.applicant?.role || '',
    } as AboutBusinessQuestions);

    const buttonRef = useRef<ButtonRef>(null);
    const [countryCode, setCountryCode] = useState("+44"); // Default country code
    const [phoneNumber, setPhoneNumber] = useState(""); // Initial phone number is empty
    const [isFormValid, setIsFormValid] = useState(false); // State to track form validity

    const countryCodes = [
        { code: "+44", name: "GB" },
        { code: "+49", name: "DE" },
        { code: "+351", name: "PT" }
    ];

    function isNullOrWhitespace(str: string | null | undefined): boolean {
        return (!str || str.length === 0 || /^\s*$/.test(str));
    }

    function isValidWebsite(website: string): boolean {
        const urlPattern = /^(https?:\/\/)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/\S*)?$/;
        return urlPattern.test(website);
    }

    const getE164PhoneNumber = () => {
        const cleanedPhoneNumber = phoneNumber?.replace(/^0+/, ''); // Remove leading zeros
        const formattedPhoneNumber = `${countryCode}${cleanedPhoneNumber}`;
        return formattedPhoneNumber;
    };

    const isValidE164 = () => {
        const e164Regex = /^\+[1-9]\d{1,14}$/;  // Regex for E.164 format
        const phoneNumberValue = getE164PhoneNumber();
        const isValid = phoneNumberValue &&
            e164Regex.test(phoneNumberValue) &&
            phoneNumberValue.length >= 10 &&
            phoneNumberValue.length <= 15;
        return isValid;
    };

    useEffect(() => {
        // Only update the phone number if it's already set in aboutBusiness (on form submission)
        if (aboutBusiness.phoneNumber && phoneNumber === "") {
            setPhoneNumber(aboutBusiness.phoneNumber);
        }
    }, [aboutBusiness.phoneNumber, phoneNumber]);

    // Validate if all required fields are filled and the phone number is in E.164 format
    useEffect(() => {
        const allFieldsFilled =
            !isNullOrWhitespace(aboutBusiness.companyName) &&
            !isNullOrWhitespace(aboutBusiness.website) &&
            isValidWebsite(aboutBusiness.website!) &&
            !isNullOrWhitespace(aboutBusiness.applicantRole) &&
            !!phoneNumber &&   // Ensure phoneNumber is not empty
            isValidE164();     // Ensure phone number is in valid E.164 format

        setIsFormValid(Boolean(allFieldsFilled));
    }, [aboutBusiness, countryCode, phoneNumber]);

    const handlePhoneNumberInput = (e: any) => {
        const inputVal = e.target.value;
        const numericOnly = inputVal.replace(/\D/g, ""); // Strip non-numeric characters
        setPhoneNumber(numericOnly);
    };

    async function onClickComplete() {
        if (isFormValid) {
            const finalPhoneNumber = getE164PhoneNumber();
            const countryCodeName = countryCodes.find(c => c.code === countryCode)?.name;
            setAboutBusiness({ ...aboutBusiness, phoneNumber: finalPhoneNumber });
            await SaveAboutBusiness({ ...aboutBusiness, phoneNumber: finalPhoneNumber, countryCode: countryCodeName });
            props.onNext();
        }
    }

    const form = (
        <PageAndContent>
            <IonGrid>
                <RowWithDynamicGutter>
                    <IonImg className="smallLogo" src="./assets/img/logo.png" />
                </RowWithDynamicGutter>
                <RowWithDynamicGutter>
                    <h1 className="sectionTitle">About Your Business</h1>
                    <p className="onboardingSubText">Please make sure all the information below is complete and correct</p>

                    <div style={{ color: '#003466' }}><h5 className="businessNameTitle">Full Name of Business*</h5></div>
                    <IonInput
                        value={aboutBusiness?.companyName}
                        className="questionInput"
                        type="text"
                        onIonChange={(event) => {
                            const newValue = event.detail.value || '';
                            setAboutBusiness({ ...aboutBusiness, companyName: newValue });
                        }}
                    ></IonInput>
                </RowWithDynamicGutter>

                <RowWithDynamicGutter>
                    <div><div style={{ color: '#003466' }}><h5 className="businessNameTitle">Business Website*</h5></div></div>
                    <IonInput
                        className="questionInput"
                        required
                        type="text"
                        value={aboutBusiness.website}
                        placeholder="e.g. https://www.example.com"
                        onIonChange={(event) => {
                            const newValue = event.detail.value || '';
                            setAboutBusiness({ ...aboutBusiness, website: newValue });
                        }}
                    ></IonInput>
                </RowWithDynamicGutter>

                <RowWithDynamicGutter>
                    <div><div style={{ color: '#003466' }}><h5 className="businessNameTitle">Your Role in The Business*</h5></div></div>
                    <IonInput
                        className="questionInput"
                        required
                        type="text"
                        value={aboutBusiness.applicantRole}
                        onIonChange={(event) => {
                            const newValue = event.detail.value || '';
                            setAboutBusiness({ ...aboutBusiness, applicantRole: newValue });
                        }}
                    />
                </RowWithDynamicGutter>

                <RowWithDynamicGutter>
                    <div><div style={{ color: '#003466' }}><h5 className="businessNameTitle">Phone Number*</h5></div></div>
                    <IonRow>
                        <IonSelect
                            className="questionInput phoneDropdown"
                            style={{ fontSize: "14px", width: '100px', paddingLeft: '0px', paddingRight: '0px' }}
                            value={countryCode}
                            onIonChange={(e) => setCountryCode(e.detail.value)}>
                            {countryCodes.map((country) => (
                                <IonSelectOption key={country.code} value={country.code}>
                                    {country.name} ({country.code})
                                </IonSelectOption>
                            ))}
                        </IonSelect>
                        <IonInput
                            className="questionInput"
                            style={{ marginLeft: "-10px" }}
                            type="tel"
                            inputmode="numeric"
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onIonInput={handlePhoneNumberInput}
                        />
                    </IonRow>
                </RowWithDynamicGutter>

                <RowWithDynamicGutter>
                    <div className="bottomButtonContainer">
                        <div className="bottomButtonRow">
                            <IonButton onClick={() => props.onNext()}>Go Back &#187;</IonButton>
                            <SubmitButton
                                disabled={!isFormValid}
                                ref={buttonRef}
                                onClick={onClickComplete}
                                text="Next »" />
                        </div>
                    </div>
                </RowWithDynamicGutter>
            </IonGrid>
            <div className="paddingDiv"></div>
        </PageAndContent>
    );

    return form;
}