import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonButton, IonCheckbox, IonImg, IonInput, IonNote, IonSpinner, IonText } from '@ionic/react';
import { OrgTypes, PayerServicesOptions, PaymentRequest, RequestPayment, VatRate } from '../../lib';
import { AccountContext } from '../../contexts/AccountContext';
import { GlobalToastContext } from '../controls/shared/GlobalToastProvider';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import _ from 'lodash';
import TakePaymentModal from './TakePaymentModal';
import GiftAidCheckbox from './components/GiftAidCheckbox';
import { RowWithSmallGutters } from '../controls/shared/Grid';
import VatRateSelectionControl from '../controls/shared/VatRateSelection';

<style>
    {`
          button {
            background-color: blue;
            color: white;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
          }
        `}
</style>

export default function TakePayment() {
    const globalToastContext = useContext(GlobalToastContext);
    const accountContext = useContext(AccountContext);
    const [paymentValue, setPaymentValue] = useState<number>();
    const [vatRate, setVatRate] = useState<VatRate | undefined>();
    const [paymentInProgress, setPaymentInProgress] = useState<boolean>(false);
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
    const [payerServicesOptions, setPayerServicesOptions] = useState<PayerServicesOptions>({
        offerGiftAid: false,
    });
    const [showDetails, setShowDetails] = useState(false);
    const [customerDetails, setCustomerDetails] = useState<string>();
    const [description, setDescription] = useState<string>();
    const lockApiCalls = useRef(false);

    const rememberAmountKey = "rememberAmount";
    const storedAmountKey = "storedAmount";
    const displayValueKey = "displayValue";
    const [rememberAmount, setRememberAmount] = useState(false);

    const [displayValue, setDisplayValue] = useState<string>('');

    useEffect(() => {
        // fixes the payment amount to a penny for Nigel's subaccounts
        // not to be used for any other purpose
        // also used to disable the amount input below
        if ((accountContext?.account?.fixedAmount || 0) > 0) {
            setPaymentValue(accountContext?.account?.fixedAmount);
        }

        // see if the remember amount checkbox was enabled previously
        let storedRememberAmountValue = localStorage.getItem(rememberAmountKey);
        if (storedRememberAmountValue === "true") {
            let storedAmount = localStorage.getItem(storedAmountKey);
            let storedDisplayValue = localStorage.getItem(displayValueKey);

            if (storedAmount &&
                !isNaN(parseFloat(storedAmount)) &&
                isFinite(parseFloat(storedAmount))) {
                setPaymentValue(parseFloat(storedAmount));
            }

            if (storedDisplayValue) {
                setDisplayValue(storedDisplayValue);
            }

            setRememberAmount(true);
        }
    }, [accountContext?.account]);

    useEffect(() => {
        if (accountContext?.merchant?.vatRates && accountContext?.merchant?.vatRates.length > 0) {
            setVatRate(accountContext.merchant.vatRates.find(rate => rate.isDefault));
        }
    }, [accountContext?.merchant?.vatRates]);

    useEffect(() => {
        if (showDetails === false) {
            setCustomerDetails(undefined);
            setDescription(undefined);
        }
    }, [showDetails]);

    function onPaymentValueChanged(val: CustomEvent) {
        const inputValue = val.detail.value!;
        
        // Convert for internal use without affecting display
        let valueForCalculation = inputValue.replace(',', '.');
        
        // Remove any additional dots/commas
        const parts = valueForCalculation.split('.');
        if (parts.length > 2) {
            valueForCalculation = parts[0] + '.' + parts.slice(1).join('');
            // Update display to match the corrected format but preserve their decimal choice
            setDisplayValue(inputValue.includes(',') 
                ? valueForCalculation.replace('.', ',')
                : valueForCalculation
            );
        } else {
            setDisplayValue(inputValue);
        }
        
        let parsedVal = parseFloat(valueForCalculation);

        if (isNaN(parsedVal)) return;

        if (parsedVal < 0) {
            parsedVal = parsedVal * -1;
            // Preserve their decimal choice when correcting negative values
            setDisplayValue(inputValue.includes(',') 
                ? parsedVal.toString().replace('.', ',')
                : parsedVal.toString()
            );
        }

        if (parsedVal > 1000000) {
            parsedVal = 1000000;
            // Preserve their decimal choice when capping the value
            setDisplayValue(inputValue.includes(',') 
                ? parsedVal.toString().replace('.', ',')
                : parsedVal.toString()
            );
        }

        setPaymentValue(_.ceil(parsedVal, 2));
    };

    function stopPaymentRequest(reason?: string) {
        setPaymentValue(undefined);
        setDisplayValue('');
        setPaymentInProgress(false);
        setPaymentRequest(undefined);
        setShowDetails(false);
        setDescription(undefined);
        setCustomerDetails(undefined);
        accountContext?.refresh();

        if (reason) {
            globalToastContext.error(reason);
        }
    };

    function onNewPaymentRequestFulfilled(data: PaymentRequest) {
        setPaymentRequest(data);
    }

    function onNewPaymentRequestRejected(reason: any) {
        stopPaymentRequest(reason);
    }

    function onNewPaymentRequestError(e: any) {
        stopPaymentRequest("Something went wrong");
    }

    function onNewPaymentRequestFinally() {
        lockApiCalls.current = false;
    }

    function onSubmitPaymentRequest() {
        if (!paymentValue) {
            globalToastContext.error(`Please provide a value in ${accountContext?.merchant?.currency == "GBP" ? "pounds and pence" : "euros and cents"}`);
            return;
        }

        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        setPaymentInProgress(true);

        RequestPayment(paymentValue!, description!, customerDetails!, payerServicesOptions, vatRate)
            .then(onNewPaymentRequestFulfilled, onNewPaymentRequestRejected)
            .catch(onNewPaymentRequestError)
            .finally(onNewPaymentRequestFinally);

        if (rememberAmount) {
            localStorage.setItem(rememberAmountKey, rememberAmount.toString());
            localStorage.setItem(storedAmountKey, paymentValue.toString());
            localStorage.setItem(displayValueKey, displayValue);
        } else {
            localStorage.removeItem(rememberAmountKey);
            localStorage.removeItem(storedAmountKey);
            localStorage.removeItem(displayValueKey);
        }
    };

    // payment extra details
    function toggleDetails(e: React.MouseEvent) {
        e.preventDefault();
        setShowDetails(!showDetails);
    };

    function customerDetailsHandleChange(event: any) {
        const result = event.target.value.replace(/[^a-z,0-9 ]/gi, '');
        setCustomerDetails(result);
    };

    function descriptionHandleChange(event: any) {
        const result = event.target.value.replace(/[^a-z,0-9 ]/gi, '');
        setDescription(result);
    };

    function updatePayerServicesOptions<K extends keyof PayerServicesOptions>(
        property: K,
        value: PayerServicesOptions[K]) {
        setPayerServicesOptions(prev => ({
            ...prev,
            [property]: value,
        }));
    };

    return <>
        <RowWithSmallGutters>
            <IonImg src='assets/img/logo.png' />

            <div className='greyPanel'>
                <IonText className='mainTitle' color='primary' class="ion-text-center ion-no-padding">Request a Payment</IonText>

                <IonInput
                    className='ion-margin-top ion-margin-bottom'
                    color={"#CCCCCC"}
                    type="number"
                    inputMode="decimal"
                    step="0.01"
                    min="0.01"
                    value={displayValue}
                    onIonChange={onPaymentValueChanged}
                    placeholder={`Amount`}
                    disabled={(accountContext?.account?.fixedAmount || 0) > 0}>
                </IonInput>

                <div>
                    <div style={{ display: "inline-flex", alignItems: "center", color: '#003466' }}>
                        <IonCheckbox style={{ borderColor: "#CCC" }}
                            checked={rememberAmount}
                            onIonChange={val => setRememberAmount(val.detail.checked)} />
                        &nbsp;Remember Amount
                    </div>
                </div>

                {accountContext?.merchant?.currency === "GBP" && 
                    (accountContext?.merchant?.companyType === OrgTypes.Charity || 
                        accountContext?.merchant?.companyType === OrgTypes.Foundation || 
                        accountContext?.merchant?.companyType === OrgTypes.CIO) && (
                    <GiftAidCheckbox onGiftAidChange={val => updatePayerServicesOptions("offerGiftAid", val)} />
                )}

                <IonButton
                    shape="round"
                    color="secondary"
                    className='ion-margin-top'
                    onClick={onSubmitPaymentRequest}>
                    {paymentInProgress ? <IonSpinner /> : <>Submit &#187;</>}
                </IonButton>

                <div className='ion-text-center ion-margin-top'>
                    <a href="#"
                        id='additionalInfo'
                        color="secondary"
                        onClick={e => toggleDetails(e)}>
                        Add Additional Details (optional)
                    </a>
                </div>

                {showDetails && <>
                    <label>Customer Details</label>
                    <IonInput
                        className='paymentInfoInputs'
                        type="text"
                        value={customerDetails}
                        onKeyUp={customerDetailsHandleChange}
                        placeholder="John Doe"
                        maxlength={30}>
                    </IonInput>

                    <label>Payment Description</label>
                    <IonInput
                        className='paymentDescriptionInputs'
                        type="text"
                        value={description}
                        onKeyUp={descriptionHandleChange}
                        placeholder="General Goods"
                        maxlength={30}>
                    </IonInput>

                    <div className='ion-text-center'>
                        <IonNote className='inputHelperMessage'>Do not use special characters</IonNote>
                    </div>
                </>}

            </div>
        </RowWithSmallGutters>

        {accountContext?.merchant?.vatRates && accountContext?.merchant?.vatRates.length > 0 &&
            <RowWithSmallGutters>
                <VatRateSelectionControl 
                    expandable={true}
                    objectsAffectedText="this payment"
                    selectedVatRate={vatRate}
                    vatRates={accountContext.merchant.vatRates} 
                    onVatRateSelected={rate => setVatRate(rate)} />
            </RowWithSmallGutters>}

        {paymentRequest && <TakePaymentModal onComplete={stopPaymentRequest} paymentRequest={paymentRequest}></TakePaymentModal>}

    </>;
}
