import { IonButton, IonCol, IonContent, IonDatetime, IonGrid, IonIcon, IonInput, IonModal, IonRow, IonSpinner, IonText, useIonRouter } from "@ionic/react";
import { format } from 'date-fns';
import { getDay, isSameDay } from 'date-fns/esm';
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { CreateStandingOrder, CreateStandingOrderPreset, StandingOrderFrequency, StandingOrderPayload, StandingOrderPreset, StandingOrderRequest } from "../../lib/data/StandingOrders";
import { AddUtcDays, GetBankHolidays, GetTodayDate } from "../../lib/util/Dates";
import { AccountContext } from "../../contexts/AccountContext";
import { ButtonRef, SubmitButton } from "../controls/shared/Buttons";
import { GlobalToastContext } from '../controls/shared/GlobalToastProvider';
import { RowWithSmallGutters } from "../controls/shared/Grid";
import { SelectInput, TextInput } from "../controls/shared/Inputs";
import StandingOrderQrCodeModal from "./StandingOrderQrCodeModal";
import { PageAndContent } from "../controls/nav/PageControls";
import Routes from "../../Routes";

export default function NewStandingOrderPage() {
    const globalToastContext = useContext(GlobalToastContext);
    const [formDisabled, setFormDisabled] = useState(true);
    const buttonRef = useRef<ButtonRef>(null);
    const buttonRefCancel = useRef<ButtonRef>(null)
    const accountContext = useContext(AccountContext);
    const location = useLocation<StandingOrderPreset>();
    const passedState = location.state;
    const [standingOrderRequest, setStandingOrderRequest] = useState<StandingOrderRequest>();
    const [payload, setPayload] = useState<StandingOrderPayload>(passedState?.payload || {});

    const [showPresetButton, setShowPresetButton] = useState(false);
    const [showPresetPopup, setShowPresetPopup] = useState(false);
    const [preset, setPreset] = useState<StandingOrderPreset>(passedState);
    const [newPresetName, setNewPresetName] = useState<string>();
    const [showSpinner, setShowSpinner] = useState(false);

    const [isDateModalOpen, setIsDateModalOpen] = useState(false);
    const [bankHolidays, setBankHolidays] = useState<Date[]>();
    const [minDate, setMinDate] = useState<Date>();
    const [maxDate, setMaxDate] = useState<Date>();
    const router = useIonRouter();

    function updatePayload<K extends keyof StandingOrderPayload>(
        property: K,
        value: StandingOrderPayload[K]) {
        setPayload(prev => ({
            ...prev,
            [property]: value,
        }));
    };

    useEffect(() => {
        if (!accountContext) return;

        if (!accountContext.merchant?.paymentMethods?.tokenIo?.standingOrdersMonitored) {
            router.push(Routes.TransactionMonitoringPage, "root"); // Use Ionic's router for navigation
        } else {
            GetBankHolidays().then(b => setBankHolidays(b));
        }
    }, [accountContext?.merchant, router]);

    useEffect(() => {
        // first get the next available business day from the day after tomorrow and set as min date for picker and default start date for SO
        var theDay = AddUtcDays(GetTodayDate(), 2);
        while (!isDateEnabled(theDay)) theDay = AddUtcDays(theDay, 1);
        updatePayload('startDate', theDay);
        setMinDate(theDay);

        // then work backwards from one year from now to find the last available business day one year hence
        theDay = AddUtcDays(GetTodayDate(), 365);
        while (!isDateEnabled(theDay)) theDay = AddUtcDays(theDay, -1);
        setMaxDate(theDay);
    }, [bankHolidays]);

    useEffect(() => {
        if (!payload) return;

        // disable saving the new standing order if insufficient data available
        setFormDisabled(!(
            payload.amount && payload.amount > 0 &&
            payload.payerName && payload.payerName > '' &&
            payload.startDate &&
            payload.frequency));

        // show the "save as preset"" button if there is data available to do so
        // or if the available data differs to the existing preset already saved
        const isDataAvailable = !!payload?.amount && payload?.frequency !== undefined && !!payload.startDate;
        const isPresetAvailable = preset !== undefined;
        const isDataChangedFromPreset =
            payload.description != preset?.payload.description || // abstract comparison is intentional
            payload.amount != preset?.payload.amount ||
            payload.numberOfPayments != preset?.payload.numberOfPayments ||
            payload.frequency != preset?.payload.frequency;
        setShowPresetButton(isDataAvailable && (!isPresetAvailable || isDataChangedFromPreset));
    }, [payload, preset]);

    async function createStandingOrder() {
        if (!payload) return;

        try {
            let result = await CreateStandingOrder(payload);
            setStandingOrderRequest(result);
        } catch (e) {
            globalToastContext.error("There was a problem creating the standing order. Please try again or contact us for support.");
            console.log(e);
        }
    }

    function onProcessComplete() {
        setPayload({});
        setStandingOrderRequest(undefined);
        buttonRef.current?.stopSpinning();
        router.push('/tabs/standingorders');
    }

    async function saveCurrentDetailsAsPreset() {
        if (!newPresetName) return;
        setShowSpinner(true)
        try {
            var response = await CreateStandingOrderPreset(newPresetName, payload);
            setPreset(response);
        }
        catch (e) {
            console.log(e);
            globalToastContext.error("There was a problem creating the preset. Please try again or contact us for support.");
        }
        finally {
            setShowSpinner(false)
            setNewPresetName(undefined);
            setShowPresetButton(false);
            setShowPresetPopup(false);
        }
    }

    function createDate(input: string | string[] | null | undefined): Date | null {
        if (typeof input === 'string') {
            // Single string input, try to create a date
            return new Date(input);
        } else if (Array.isArray(input)) {
            // Array of strings, use the first element to create a date
            return input.length > 0 ? new Date(input[0]) : null;
        } else {
            // Input is null or undefined, return null
            return null;
        }
    }

    function goBack() {
        router.goBack();
    }


    function cancelPresetCreation() {
        buttonRefCancel.current?.stopSpinning();
        setNewPresetName(undefined);
        setShowPresetPopup(false)
        setShowPresetPopup(false);
    }

    function isBankHoliday(date: Date): boolean {
        if (!bankHolidays) return false;
        return bankHolidays.some((holiday) => isSameDay(date, holiday));
    };

    function isDateEnabled(date: Date): boolean {
        // Disable weekends (Saturday or Sunday)
        const dayOfWeek = getDay(date);
        if (dayOfWeek === 0 /* Sunday */ || dayOfWeek === 6 /* Saturday */) {
            return false;
        }

        // Disable bank holidays
        if (isBankHoliday(date)) {
            return false;
        }

        // Enable all other days
        return true;
    }

    function isDateStringEnabled(dateIsoString: string): boolean {
        const date = new Date(dateIsoString);
        return isDateEnabled(date);
    };

    const initialForm = <>
        {preset && <RowWithSmallGutters>
            <TextInput
                disabled={true}
                value={preset.name}
                label="Preset Name"
                maxlength={30} />
        </RowWithSmallGutters>}
        <RowWithSmallGutters>
            <TextInput
                onChange={(val) => updatePayload('payerName', val)}
                value={payload.payerName}
                label="Customer Name"
                maxlength={30}
                placeholder="Enter name here" />
        </RowWithSmallGutters>
        <RowWithSmallGutters>
            <TextInput
                label='Customer&#39;s Business Name (optional)'
                onChange={(val) => updatePayload('payerBusinessName', val)}
                value={payload?.payerBusinessName}
                maxlength={30}
                placeholder="Enter business name here" />
        </RowWithSmallGutters>
        <RowWithSmallGutters>
            <TextInput
                label='Payment Amount'
                onChange={(val) => updatePayload('amount', parseFloat(val || "0"))}
                value={payload?.amount?.toString()}
                type="number"
                placeholder="Enter amount here" />
        </RowWithSmallGutters>
        <RowWithSmallGutters>
            <IonText style={{ color: '#003466', fontSize: '14px', fontWeight: '500' }}>Start Date</IonText>
            <IonInput
                style={{ marginTop: '0px', marginBottom: '10px', height: '30px' }}
                value={payload.startDate ? format(payload.startDate, "dd/MM/yyyy") : ''}
                onClick={() => setIsDateModalOpen(true)}
            />
            {minDate && maxDate && <IonModal className="datePickerModal" isOpen={isDateModalOpen} onDidDismiss={() => setIsDateModalOpen(false)}>
                <IonDatetime
                    class="datePicker"
                    locale="en-GB"
                    presentation="date"
                    min={format(minDate, "yyyy-MM-dd'T'HH:mm:ss")}
                    max={format(maxDate, "yyyy-MM-dd'T'HH:mm:ss")}  /* Set max date dynamically */
                    onIonChange={(e) =>
                        updatePayload('startDate', createDate(e.detail.value) || minDate)
                    }
                    value={payload.startDate ? format(payload.startDate, "yyyy-MM-dd'T'HH:mm:ss") : ''}
                    isDateEnabled={isDateStringEnabled}
                ></IonDatetime>
                <IonButton onClick={() => setIsDateModalOpen(false)}>Confirm</IonButton>
            </IonModal>}

        </RowWithSmallGutters>
        <RowWithSmallGutters>
            <TextInput
                label='Description (optional)'
                onChange={(val) => updatePayload('description', val)}
                value={payload?.description}
                maxlength={50}
                placeholder="Enter description here" />
        </RowWithSmallGutters>
        <RowWithSmallGutters>
            <SelectInput
                label="Payment Frequency"
                selected={payload.frequency}
                onChange={(val) => updatePayload('frequency', val)}
                options={[
                    { value: StandingOrderFrequency.Weekly, label: "Weekly" },
                    { value: StandingOrderFrequency.Monthly, label: "Monthly" },
                    { value: StandingOrderFrequency.Annually, label: "Annually" }
                ]}
                placeholder="Select your frequency" />
        </RowWithSmallGutters>
        <RowWithSmallGutters>
            <TextInput
                label='Number of Payments'
                subLabel="Leave blank if there is no end date"
                onChange={(val) => updatePayload('numberOfPayments', parseInt(val || "0"))}
                value={payload?.numberOfPayments?.toString()}
                type="number"
                placeholder="Number of payments" />
        </RowWithSmallGutters>
        {showPresetButton &&
            <RowWithSmallGutters>
                <div style={{ alignContent: 'left', width: '230px' }}>
                    <SubmitButton
                        class="savePresetButton"
                        onClick={() => setShowPresetPopup(true)}
                        text="Save As A Payment Preset &#187;"
                        colour="primary"
                        ref={buttonRefCancel} />
                </div>
            </RowWithSmallGutters>}
        <RowWithSmallGutters>
            <SubmitButton disabled={formDisabled}
                onClick={() => createStandingOrder()}
                text="Set Up &#187;"
                ref={buttonRef} />
        </RowWithSmallGutters>
        <RowWithSmallGutters>
            <IonText style={{ fontSize: '11px', fontWeight: '200', color: '#003466', textAlign: 'left', lineHeight: 'normal' }}>
                By pressing this button you agree you have read and accept our <a href='https://trustisttransfer.com/terms-and-conditions/' target="_blank" rel="noreferrer">terms and conditions.</a>
            </IonText>
        </RowWithSmallGutters>
    </>;

    const presetPopup = <>
        <IonModal className='smallModal' backdropDismiss={false} isOpen={true}>
            <IonContent className="modelContent">
                <IonText style={{ color: '#003466', textAlign: 'center' }}>
                    <b>
                        <div className='modalStrongText'>
                            Name your Payment Preset
                        </div>
                    </b>
                </IonText>
                <div style={{ color: '#003466', fontWeight: 500, marginRight: '20px', marginLeft: "10px", marginTop: "10px", marginBottom: '20px' }}>
                    <TextInput
                        onChange={(val) => setNewPresetName(val)}
                        value={newPresetName}
                        maxlength={30} />
                </div>
                <IonRow style={{ textAlign: 'center' }}>
                    <IonCol style={{ marginLeft: '-10px' }} className="bottomColButton" onClick={cancelPresetCreation}>
                        <IonText style={{ fontSize: '17px', fontWeight: '400' }}>Cancel</IonText>
                    </IonCol>
                    <IonCol style={{ marginRight: '-10px' }} className="bottomColButton" onClick={saveCurrentDetailsAsPreset}>
                        {showSpinner == true ? <div><IonSpinner style={{ marginLeft: '-15px', color: '#fff' }} /></div> : <IonText style={{ fontSize: '17px', fontWeight: '400' }}>Save</IonText>}
                    </IonCol>
                </IonRow>

            </IonContent>
        </IonModal>
    </>;

    var toolbar = <>
        <div style={{ position: 'absolute', bottom: '8px', left: '15px' }}>
            <IonButton onClick={goBack} className="circularButton">
                <IonIcon className="circularButtonContent" src="assets\icon\chevron-back-outline.svg" />
            </IonButton>
        </div>
        <IonText class='titleBarText'>Create Standing Order</IonText>
    </>;

    return <>
        <PageAndContent toolbarChildren={toolbar}>
            <IonGrid>
                {initialForm}
            </IonGrid>
        </PageAndContent>

        {showPresetPopup && presetPopup}
        {standingOrderRequest && <StandingOrderQrCodeModal onComplete={() => onProcessComplete()} standingOrderRequest={standingOrderRequest!} />}
    </>;
}