import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";

import {
    InfiniteScrollCustomEvent,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonList,
    IonModal,
    IonRouterLink,
    IonRow,
    IonSpinner,
    IonText,
    useIonRouter
} from "@ionic/react";
import { ListPaymentPresets, PaymentPreset, DeletePaymentPreset, PaymentPresetPayload } from "../../lib/data/PaymentPresets";
import Spinner from "../controls/shared/Spinner";
import Routes from "../../Routes";
import { PageAndContent } from "../controls/nav/PageControls";
import ShareUrlButton from "../controls/shared/ShareUrlButton";

export default function PaymentPresetsListPage() {
    const history = useHistory();
    const location = useLocation();
    const continuation = useRef<string | undefined>(undefined);
    const [searchResults, setSearchResults] = useState<PaymentPreset[] | undefined>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedPresetId, setSelectedPresetId] = useState<string | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const lockApiCalls = useRef(false);
    const router = useIonRouter();
    const [showShareModal, setShowShareModal] = useState(false);
    const [preset, setPreset] = useState<PaymentPreset | undefined>(); // Updated state to hold PaymentPreset

    async function getDataAsync(ev?: InfiniteScrollCustomEvent) {
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        try {
            const response = await ListPaymentPresets(continuation.current);

            if ((response?.data?.length ?? 0) === 0) {
                if (response.continuation === null) setSearchResults([]);
                return;
            }

            (continuation?.current && continuation.current > '')
                ? setSearchResults([...searchResults!, ...response.data])
                : setSearchResults(response.data);

            continuation.current = response.continuation;
        }
        catch (e) {

        }
        finally {
            lockApiCalls.current = false;
            if (ev) ev.target.complete();
        }
    }

    useEffect(() => {
        if (!location.pathname.toLowerCase().startsWith(Routes.PaymentPresetsListPage.toLowerCase())) return;
        continuation.current = undefined;
        getDataAsync();
    }, [location.pathname]);

    async function deletePreset() {
        if (!selectedPresetId) return;

        try {
            setIsDeleting(true);
            await DeletePaymentPreset(selectedPresetId);
            setSearchResults(searchResults?.filter(preset => preset.id !== selectedPresetId));
        } catch (e) {
            console.error("Error deleting preset:", e);
        } finally {
            setIsDeleting(false);
            setShowDeleteModal(false);
        }
    }


    const searchResultList = <>
        <IonGrid fixed>
            <IonList>
                {searchResults?.map((item, index) => {
                    return (
                        <IonItem className='listItem' id={`payment_${item.id}`} key={item.id} onClick={() => history.push("/tabs/paymentpreset", item)}>
                            <IonRow style={{ width: '100%' }}>
                                <IonCol size="6">
                                    <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}><p style={{ color: '#003466', }}><b>{item.name}</b></p></div>
                                </IonCol>
                                <IonCol size="6" style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                    <IonButton className="icon-button" style={{ width: '30px', marginLeft: "10px", marginTop: "12px" }} onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(`/tabs/paymentpreset`, {
                                            ...item,
                                            editMode: true, // This will allow the item to be in edit mode
                                        });
                                    }}><IonIcon src=".\assets\icon\editPresetPen.svg"></IonIcon> </IonButton>
                                    <IonButton className="icon-button" style={{ width: '30px', marginLeft: "10px", marginTop: "12px" }} onClick={(e) => {
                                        e.stopPropagation()
                                        setSelectedPresetId(item.id);
                                        setShowDeleteModal(true);
                                    }}><IonIcon src=".\assets\icon\deletePresetBin.svg"></IonIcon></IonButton>
                                    <IonButton className="icon-button" style={{ width: '30px', marginLeft: "10px", marginTop: "12px" }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setPreset(item);
                                            setShowShareModal(true);
                                        }}><IonIcon src=".\assets\icon\cardIconPreset.svg"></IonIcon></IonButton>
                                </IonCol>
                            </IonRow>
                        </IonItem>
                    );
                })}
                {continuation?.current && <IonInfiniteScroll threshold="200px" onIonInfinite={getDataAsync}>
                    <IonInfiniteScrollContent loadingSpinner="circular" loadingText="Loading...">
                    </IonInfiniteScrollContent>
                </IonInfiniteScroll>}
            </IonList>
        </IonGrid>
    </>;

    var toolbar = <>
        <div style={{ position: 'absolute', bottom: '8px', left: '15px' }}>
            <IonButton onClick={() => router.goBack()} className="circularButton">
                <IonIcon className="circularButtonContent" src="assets\icon\chevron-back-outline.svg" />
            </IonButton>
        </div>
        <IonText class='titleBarText'>Payment Presets</IonText>
    </>;

    function drawShareModal() {
        return (
            <IonModal backdropDismiss={true} isOpen={true}>
                <IonContent>
                    <IonRow className='modalTitleRow'>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"></IonCol>
                        <IonCol sizeXs="10" sizeSm="8" sizeMd="6" sizeLg="6" sizeXl="6" class='ion-text-center'>
                            <IonText color='primary'>
                                <h1 className='scanBelowText'>Scan below to share preset</h1>
                            </IonText>
                        </IonCol>
                        <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3"> </IonCol>
                        <IonCol sizeXs="10" sizeSm="8" sizeMd="6" sizeLg="6" sizeXl="6">
                            <br />
                            <IonImg src={`/api/qrcode?logo=tt1&content=${preset?.shareUrl}`} />
                            <br />
                            {preset?.shareUrl && (
                                <ShareUrlButton url={preset.shareUrl} buttonText="Share PayLink »" />
                            )}
                        </IonCol>
                        <IonCol sizeXs="1" sizeSm="2" sizeMd="3" sizeLg="3" sizeXl="3" > </IonCol>
                    </IonRow>

                    <div className="ion-text-center" >
                        <IonRouterLink
                            className='closeModal'
                            style={{ textDecoration: "underline" }}
                            onClick={() => setShowShareModal(false)}>
                            <b>Back</b>
                        </IonRouterLink>
                    </div>
                </IonContent>
            </IonModal>
        );

    }

    return <>
        <PageAndContent toolbarChildren={toolbar}>
            <IonRow style={{
                justifyContent: 'center',
            }}>
                <IonCol sizeXs='1' sizeSm='2' sizeMd='3' sizeLg='4' sizeXl='4.5'></IonCol>
                <IonCol sizeXs='10' sizeSm='8' sizeMd='6' sizeLg='4' sizeXl='3'>
                    <IonButton color="primary"
                        onClick={() => router.push("/tabs/paymentpreset")}>Create New &#187;
                    </IonButton>
                </IonCol>
                <IonCol sizeXs='1' sizeSm='2' sizeMd='3' sizeLg='4' sizeXl='4.5'></IonCol>
            </IonRow>
            <IonGrid className="tabPageBottomMargin">
                {searchResults ? searchResultList : <Spinner />}
            </IonGrid>
        </PageAndContent>

        {showShareModal && drawShareModal()}

        <IonModal className='smallModal' backdropDismiss={true} isOpen={showDeleteModal}>
            <IonContent className="modelContent">
                <IonText style={{ color: '#003466', textAlign: 'center' }}>
                    <b>
                        <div style={{ fontSize: '17px', marginTop: '8px' }}>
                            Are you sure you want to delete this preset?
                        </div>
                    </b>
                    <p style={{ fontWeight: '500' }}>This action cannot be undone</p>
                </IonText>
                <IonRow style={{ textAlign: 'center' }}>
                    <IonCol className="bottomColButton" onClick={() => setShowDeleteModal(false)}>
                        <IonText style={{ fontSize: '17px', fontWeight: '400' }}>No</IonText>
                    </IonCol>
                    <IonCol className="bottomColButton" onClick={deletePreset}>
                        {isDeleting
                            ? <IonSpinner style={{ marginLeft: '-15px', color: '#fff' }} />
                            : <IonText style={{ fontSize: '17px', fontWeight: '400' }}>Yes</IonText>}
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonModal>


    </>;
}