import { ApiFileDownload, ApiGet, ApiPost, VatRate } from "..";
import { format } from 'date-fns';
import { PaymentPresetBase } from "./PaymentPresets";

export type PaymentRequest = {
    amount: number,
    payLink: string | undefined,
    paymentId: string | undefined,
    clientDateCreated: string,
    payerServicesOptions: PayerServicesOptions,
};

export type PayerServicesOptions = {
    offerGiftAid: boolean,
};

export type Payment = {
    id: string,
    status: string,
    amount: number,
    currency: string,
    currencySymbol: string,
    description: string,
    customerDetails: string,
    created: Date,
    clientDateCreatedText: string,
    clientDateCreated: string,
    invoiceId: string,
    reference: string,
    orgName?: string,
    payerName?: string,
    type?: string,
    paymentMethod?: PaymentMethod,
    preset?: PaymentPresetBase,
};

export type PaymentList = {
    data: Payment[],
    continuation: string | undefined,
};

export enum PaymentMethod {
    NotAttempted = 0,
    OpenBanking = 10,
    Card = 20,
};

export const RequestPayment = async (
    amount: number,
    description: string,
    customerDetails: string,
    payerServicesOptions: PayerServicesOptions,
    vatRate?: VatRate): Promise<PaymentRequest> => {

    var url = 'RequestPayment?';
    var response = await ApiPost(
        url, {
        amount,
        description,
        customerDetails,
        clientDateCreated: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx"),
        payerServicesOptions,
        vatRate,
    });

    if (response.status === 200) {
        return response?.data;
    }

    return {
        amount,
        payLink: undefined,
        paymentId: undefined,
        clientDateCreated: '',
        payerServicesOptions,
    };
}

export const ListPayments = async (
    continuation: string | undefined,
    pageSize?: number,
    status?: string,
    description?: string,
    start?: string,
    end?: string,
    subMerchantName?: string,
    justMyOwn: boolean = false,
    type?: string): Promise<PaymentList> => {

    var url = 'GetPaymentHistory?';
    if (continuation) url += `continuation=${continuation}&`;
    if (pageSize) url += `pageSize=${pageSize}&`;
    if (status) url += `status=${status}&`;
    if (description) url += `description=${description}&`;
    if (start) url += `start=${start}&`;
    if (end) url += `end=${end}&`;
    if (subMerchantName) url += `subMerchantName=${subMerchantName}&`;
    if (justMyOwn === true) url += 'justMyOwn=true&';
    if (type) url += `type=${type}`;
    var response = await ApiGet(url);

    if (response.status === 200) {
        return response?.data;
    }

    return { data: new Array<Payment>(), continuation: undefined };
    // do something?
}

export const GetPaymentResult = async (paymentId: string): Promise<Payment> => {
    var url = 'GetPaymentResult?';
    if (paymentId !== '') url += `paymentId=${paymentId}&`;
    var response = await ApiGet(url);

    if (response.status === 200) {
        return response?.data;
    }

    return {
        id: '',
        status: '',
        amount: 0,
        currency: '',
        currencySymbol: '',
        description: '',
        customerDetails: '',
        created: new Date(),
        clientDateCreated: '',
        clientDateCreatedText: '',
        invoiceId: '',
        reference: '',
        type: '',
        preset: undefined,
    };
}

export const PaymentStatusColor = (payment: Payment | undefined): string => {
    if (!payment) return "";

    if ((payment.status === 'PENDING') || (payment.status === 'PROCESSING') || (payment.status === 'STARTED')) {
        return 'paymentStatusPendingOrInProgress'
    }
    else if ((payment.status === 'SUCCESS') || (payment.status === 'COMPLETE')) {
        return 'paymentStatusSuccess'
    }
    else {
        return 'paymentStatusFailed'
    }
}

export const DownloadTransactionsAsync = async (startDate: Date | null, endDate: Date | null): Promise<boolean> => {
    var url = "DownloadTransactions?";
    if (startDate) url += `start=${format(startDate, "yyyy-MM-dd")}&`;
    if (endDate) {
        var dayAfterEndDate = new Date(endDate);
        dayAfterEndDate.setDate(dayAfterEndDate.getDate() + 1);
        url += `end=${format(dayAfterEndDate, "yyyy-MM-dd")}&`;
    }

    var response = await ApiFileDownload(url);
    return response;
}
